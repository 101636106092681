import { ShaderMaterial } from 'three';
import { defineComponent, inject, onUnmounted, provide, reactive } from 'vue';

import { GPGPU, simpleVs } from '@resn/gozer-three';
import { ShaderPass } from '@resn/gozer-three/passes';

import glslSnoise from '@/libs/lygia/generative/snoise.glsl';
import glslVoronoi from '@/libs/lygia/generative/voronoi.glsl';
import glslScale from '@/libs/lygia/space/scale.glsl';

const NoiseTexturesKey = 'noiseTextures';
export const useNoiseTextures = () => {
    const noiseTextures = inject(NoiseTexturesKey, {});
    return noiseTextures;
};

export const NoiseTextures = defineComponent({
    setup() {
        const { renderer } = inject('renderer');

        const props = reactive({});

        const gns = [];

        const generateNoise = ({ size = 512, offset = { x: 0, y: 0 } } = {}) => {
            const gn = new GPGPU(renderer, { data: new Float32Array(size * size * 4) });
            gn.addPass({
                fragmentShader: /* glsl */ `
                    varying vec2 vUv;
                    uniform vec2 u_offset;
                    ${glslSnoise}
                    void main() {
                        vec2 st = vUv;
                             st += u_offset;
                        float n = snoise(st);
                        vec4 res = vec4(0.);
                             res.xyz = vec3(n);
                        gl_FragColor = res;
                    }
                `,
                uniforms: {
                    u_offset: { value: offset },
                },
            });
            gn.render();
            gns.push(gn);
            return gn.uniform;
        };

        const generateVoronoi = ({ size = 1024, scale = 100, offset = { x: 0, y: 0 } } = {}) => {
            const gn = new ShaderPass(renderer, {
                width: size,
                height: size,
                shader: new ShaderMaterial({
                    vertexShader: simpleVs,
                    fragmentShader: /* glsl */ `
                        varying vec2 vUv;
                        uniform vec2 u_offset;
                        uniform float u_scale;
                        ${glslVoronoi}
                        ${glslScale}
                        void main() {
                            vec2 st = vUv;
                                 st = scale(st, u_scale);
                            vec4 res = vec4(0.);
                                 res.xyz = voronoi(st);
                            gl_FragColor = res;
                        }
                    `,
                    uniforms: {
                        u_offset: { value: offset },
                        u_scale: { value: scale },
                    },
                }),
            });
            gn.render({ final: false });
            gns.push(gn);
            return { value: gn.texture };
        };

        onUnmounted(() => {
            gns.forEach((_) => _.dispose());
        });

        provide(NoiseTexturesKey, {
            props,
            generateNoise,
            generateVoronoi,
        });
    },

    render() {
        return this.$slots.default();
    },
});
